import principalSignature from "assets/img/partials/principal-signature.png";
import vicePrincipalSignature from "assets/img/partials/vice-principal-signature.png";
import { Col, Row } from "reactstrap";
import SheetHeader from "../SheetHeader";
import SheetStudentInfo from "../SheetStudentInfo";

const FirstSemesterSheet = ({ result, highestMarks, classNumeric }) => {
  const { marks, qualities, total, ...studentInfo } = result;
  const quality = qualities[0] || {};

  console.log(highestMarks);

  const getHighestMark1stSem = (subjectId) => {
    console.log(subjectId);
    const result =
      Math.round(
        highestMarks?.find(
          (item) =>
            item.semester === "1stSemester" && item?.subjectId === subjectId
        )?.highestMark
      ) || 0;
    return result || 0;
  };

  return (
    <div style={{ margin: "8px" }} className="modal-body modal-body-custom">
      <SheetHeader />
      <div className="customResult">
        <SheetStudentInfo
          studentInfo={studentInfo}
          semesterName={"Half Yearly"}
        />
        <div>
          <table style={{ width: "100%", flex: "1" }} className="customTable">
            <thead>
              <tr>
                <th className="customTh" rowSpan={2}>
                  SUBJECT
                </th>
                <th className="customTh" colSpan={5}>
                  Half Yearly Examination
                </th>
                <th className="customTh" rowSpan={2}>
                  G.Total
                </th>
                <th className="customTh" rowSpan={2}>
                  Grade
                </th>
                <th className="customTh" rowSpan={2}>
                  Highest
                </th>
              </tr>
              <tr>
                <th className="customTh">Class Test</th>
                <th className="customTh">SBA</th>
                <th className="customTh">Assignment</th>
                <th className="customTh">Term</th>
                <th className="customTh">Total</th>
              </tr>
            </thead>
            <tbody>
              {marks?.map((item, idx) => (
                <tr key={idx}>
                  <td className="customTd">{item?.subjectName || ""}</td>
                  <td className="customTd">{item?.classTest || ""}</td>
                  <td className="customTd">{item?.sba || ""}</td>
                  <td className="customTd">{item?.assignment || ""}</td>
                  <td className="customTd">{item?.term || ""}</td>
                  <td className="customTd">{item?.total || ""}</td>
                  <td className="customTd">{item?.total || ""}</td>
                  <td className="customTd">{item?.grade || ""}</td>
                  <td className="customTd">
                    {getHighestMark1stSem(item?.subjectId) || ""}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="customTd font-weight-bold">Total Mark</td>
                <td colSpan={5} className="customTd"></td>
                <td className="customTd font-weight-bold">{total}</td>
                <td colSpan={2} className="customTd"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div>
        <p style={{ fontWeight: "bold" }} className="ndPart">
          <u>Evaluation</u>
        </p>
      </div>
      <div>
        <p className="ndPart">Results</p>
      </div>
      <div className="ndPart d-flex justify-content-between p-3">
        <div>
          <table className="customTable">
            <thead>
              <tr>
                <th className="customThg" scope="col">
                  Grade
                </th>
                <th className="customThg" scope="col">
                  Meaning
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="customTdg">A+</td>
                <td className="customTdg">Excellent</td>
              </tr>
              <tr>
                <td className="customTdg">A</td>
                <td className="customTdg">Good</td>
              </tr>
              <tr>
                <td className="customTdg">B+</td>
                <td className="customTdg">Satisfactory</td>
              </tr>
              <tr>
                <td className="customTdg">B</td>
                <td className="customTdg">Needs Improvement</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="customTable">
            <thead>
              <tr>
                <td className="customThg">Regularity</td>
                <td style={{ width: "120px" }} className="customThg">
                  {quality?.regularity || ""}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="customTdg">Patriotism</td>
                <td className="customTdg">{quality?.patriotism || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Honesty</td>
                <td className="customTdg">{quality?.honesty || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Leadership</td>
                <td className="customTdg">{quality?.leadership || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Discipline</td>
                <td className="customTdg">{quality?.discipline || ""}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="customTable">
            <thead>
              <tr>
                <td className="customThg">Participation</td>
                <td style={{ width: "120px" }} className="customThg">
                  {quality?.participation || ""}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="customTdg">Tolerance</td>
                <td className="customTdg">{quality?.tolerance || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Awareness</td>
                <td className="customTdg">{quality?.awareness || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Punctuality</td>
                <td className="customTdg">{quality?.punctuality || ""}</td>
              </tr>
              <tr>
                <td className="customTdg">Handwriting</td>
                <td className="customTdg">{quality?.handwriting || ""}</td>
              </tr>

              {Number(classNumeric) >= 3 && Number(classNumeric) <= 5 ? (
                <tr>
                  <td className="customTdg">Arts & Crafts</td>
                  <td className="customTdg">{quality?.arts_crafts || ""}</td>
                </tr>
              ) : (
                <tr>
                  <td className="customTdg">Music</td>
                  <td className="customTdg">{quality?.music || ""}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Row className="mt-5">
        <Col md="4" sm="4" lg="4" className="text-center">
          <img style={{ width: "90px" }} src={principalSignature} alt="" />
          <p>----------------</p>
          <h5>Principal</h5>
        </Col>
        <Col md="4" sm="4" lg="4" className="text-center mt-4">
          <p className="mt-2">----------------</p>
          <h5>Class Teacher</h5>
        </Col>
        <Col md="4" sm="4" lg="4" className="text-center">
          <img style={{ width: "90px" }} src={vicePrincipalSignature} alt="" />
          <p>----------------</p>
          <h5>Vice-Principal</h5>
        </Col>
      </Row>
    </div>
  );
};

export default FirstSemesterSheet;
